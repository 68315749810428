/* eslint-disable import/prefer-default-export */
import * as performanceApi from '../../api/performanceManagerApi';
import * as types from './actionTypes';
import MetricsScorecardModel, {
  MetricsModel,
  CapModel
} from '../../components/models/model/metricsScorecardModel';
import { Message } from '../../common/consts/validationMessages';
import { Logger } from '../../infra/logging/telemetryLogger';
import handleValidationError from './validationErrorHandling';

function buildScorecardDataToBeSubmitted(
  categoryScorecardId,
  metricScorecardDetails
) {
  let capDetails = null;
  if (metricScorecardDetails.cap && metricScorecardDetails.cap.description) {
    capDetails = new CapModel(

      metricScorecardDetails.cap.id,
      metricScorecardDetails.cap.description,
      metricScorecardDetails.cap.openDate,
      metricScorecardDetails.cap.dueDate,
      metricScorecardDetails.cap.isCompleted || false,
      metricScorecardDetails.cap.completedDate,
      false
    );
  }
  const metrics = [];
  if (metricScorecardDetails.metrics) {
    metricScorecardDetails.metrics.forEach(metric => {
      const subMetrics = [];
      if (metric.subMetrics) {
        metric.subMetrics.forEach(subMetric => {
            const subMetricData = new MetricsModel(
              subMetric.id,
              subMetric.isApplicable,
              subMetric.score ? String(subMetric.score) : String(subMetric.score),
              subMetric.weight ? String(subMetric.weight) : subMetric.weight,
              null
            );
            subMetrics.push(subMetricData);
        });
      }
      const metricData = new MetricsModel(
        metric.id,
        metric.isApplicable,
        metric.score ? String(metric.score) : String(metric.score),
        metric.weight ? String(metric.weight) : metric.weight,
        subMetrics
      );
      metrics.push(metricData);
    });
  }
  const bobScoreCardUpdateRequest = new MetricsScorecardModel(
    metrics,
    metricScorecardDetails.comments,
    capDetails,
    categoryScorecardId,
    String(metricScorecardDetails.score)
  );
  return bobScoreCardUpdateRequest;
}

function requestProcessedMessageSuccess(message) {
  return { type: types.ADD_PROCESSEDMESSAGE_SUCCESS, message };
}

function requestUnprocessedMessageSuccess(message, tcvId) {
  return { type: types.ADD_UNPROCESSEDMESSAGE_SUCCESS, message, tcvId };
}
function FetchCategoryDetailsInit(isLoading) {
  return {
    type: types.FETCH_CATEGORYDATA_INIT,
    payload: isLoading
  };
}

function FetchSummaryDetailsInit(isLoading) {
  return {
    type: types.FETCH_SUMMARYDATA_INIT,
    payload: isLoading
  };
}
function loadBobScorecardDetailsSuccess(bobScorecardDetails) {
  return {
    type: types.LOAD_BOBSCORECARDDETAILS_SUCCESS,
    payload: bobScorecardDetails
  };
}

function loadSupplierScorecardDetailsSuccess(supplierScorecardDetails) {
  return {
    type: types.LOAD_SUPPLIERSCORECARDDETAILS_SUCCESS,
    payload: supplierScorecardDetails
  };
}

function loadBobScorecardCategoryDetailsSuccess(bobScorecardCategoryDetails) {
  return {
    type: types.LOAD_BOBSCORECARD_CATEGORYDETAILS_SUCCESS,
    payload: bobScorecardCategoryDetails
  };
}

function loadSupplierScorecardCategoryDetailsSuccess(
  supplierScorecardCategoryDetails
) {
  return {
    type: types.LOAD_SUPPLIERSCORECARD_CATEGORYDETAILS_SUCCESS,
    payload: supplierScorecardCategoryDetails
  };
}

export const updateNotApplicable = (itemId, subItemId, isChecked) => ({
  type: types.METRIC_APPLICABILITY_CHANGE,
  payload: {
    itemId, subItemId, isChecked
  }
});

export const updateCategoryNotApplicable = (isChecked, prevCategoryDetails) => ({
  type: types.METRIC_APPLICABILITY_ALL_CHANGE,
  payload: {
    isChecked, prevCategoryDetails
  }
});

export const updateMetricRating = (itemId, subItemId, rating, selectedScorecardCategory) => ({
  type: types.METRIC_RATING_CHANGE,
  payload: {
     itemId, subItemId, rating, selectedScorecardCategory
     }
});

export const updateCapDetails = (key, value) => ({
  type: types.UPDATE_CAP_DETAILS,
  payload: { key, value }
});

export const updateScorecardCategoryComments = Comments => ({
  type: types.CATEGORY_COMMENTS_CHANGE,
  payload: Comments
});

function loadSupplierScorecardAvgBOBScoreHistorySuccess(history) {
  return {
    type: types.LOAD_SUPPLIERSCORECARD_CATEGORY_AVGBOBSCOREHISTORY_SUCCESS,
    history
  };
}

export function loadBobScorecardDetails(supplierId, bobId, cadenceId) {
  return async dispatch => {
    try {
      dispatch(FetchSummaryDetailsInit(true));
      const response = await performanceApi.getBobScorecardDetails(
        supplierId,
        bobId,
        cadenceId
      );
      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(loadBobScorecardDetailsSuccess(response.data));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    } finally {
      dispatch(FetchSummaryDetailsInit(false));
    }
  };
}

export function loadSupplierScorecardDetails(supplierId, cadenceId) {
  return async dispatch => {
    try {
      dispatch(FetchSummaryDetailsInit(true));
      const response = await performanceApi.getSupplierScorecardDetails(
        supplierId,
        cadenceId
      );

      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(loadSupplierScorecardDetailsSuccess(response.data));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    } finally {
      dispatch(FetchSummaryDetailsInit(false));
    }
  };
}

export function loadBobScorecardCategoryDetails(categoryId) {
  return async dispatch => {
    try {
      dispatch(FetchCategoryDetailsInit(true));
      const response = await performanceApi.getBobScorecardCategoryDetails(
        categoryId
      );
      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(loadBobScorecardCategoryDetailsSuccess(response.data));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    } finally {
      dispatch(FetchCategoryDetailsInit(false));
    }
  };
}

export function loadSupplierScorecardCategoryDetails(categoryId) {
  return async dispatch => {
    try {
      dispatch(FetchCategoryDetailsInit(true));
      const response = await performanceApi.getSupplierScorecardCategoryDetails(
        categoryId
      );
      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(loadSupplierScorecardCategoryDetailsSuccess(response.data));
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    } finally {
      dispatch(FetchCategoryDetailsInit(false));
    }
  };
}

function loadSupplierScorecardMetricHistorySuccess(history) {
  return {
    type: types.LOAD_SUPPLIERSCORECARD_METRICHISTORY_SUCCESS,
    history
  };
}

export function loadSupplierScorecardMetricHistory(categoryId, metricId) {
  return async dispatch => {
    try {
      const response = await performanceApi.getSupplierScorecardMetricHistory(
        categoryId,
        metricId
      );
      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(
          loadSupplierScorecardMetricHistorySuccess({
            metricId,
            metricHistory: response.data
          })
        );
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

function loadBobScorecardMetricHistorySuccess(history) {
  return {
    type: types.LOAD_BOBSCORECARD_METRICHISTORY_SUCCESS,
    history
  };
}

export function loadBobScorecardMetricHistory(categoryId, metricId) {
  return async dispatch => {
    try {
      const response = await performanceApi.getBobScorecardMetricHistory(
        categoryId,
        metricId
      );

      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(
          loadBobScorecardMetricHistorySuccess({
            metricId,
            metricHistory: response.data
          })
        );
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

function loadSupplierScorecardCategoryHistorySuccess(history) {
  return {
    type: types.LOAD_SUPPLIERSCORECARD_CATEGORYHISTORY_SUCCESS,
    history
  };
}

export function loadSupplierScorecardCategoryHistory(categoryId) {
  return async dispatch => {
    try {
      const response = await performanceApi.getSupplierScorecardCategoryHistory(
        categoryId
      );

      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(
          loadSupplierScorecardCategoryHistorySuccess({
            categoryId,
            categoryHistory: response.data
          })
        );
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

function loadBobScorecardCategoryHistorySuccess(history) {
  return {
    type: types.LOAD_BOBSCORECARD_CATEGORYHISTORY_SUCCESS,
    history
  };
}

export function loadBobScorecardCategoryHistory(categoryId) {
  return async dispatch => {
    try {
      const response = await performanceApi.getBobScorecardCategoryHistory(
        categoryId
      );

      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        dispatch(
          loadBobScorecardCategoryHistorySuccess({
            categoryId,
            categoryHistory: response.data
          })
        );
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      Logger.getInstance().logException(error);
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
    }
  };
}

function loadSupplierScorecardAvgBobScoreHistorySuccess(history) {
  return {
    type: types.LOAD_SUPPLIERSCORECARD_CATEGORYHISTORY_SUCCESS,
    history
  };
}

export function loadSupplierScorecardAvgBobScoreHistory(categoryId) {
  return async dispatch => {
    try {
      const response = await performanceApi.getAvgBobScoreHistoryForCategory(
        categoryId
      );
      if (response.validationResult.isValid) {
        dispatch(
          loadSupplierScorecardAvgBobScoreHistorySuccess({
            categoryId,
            response
          })
        );
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      Logger.getInstance().logException(error);
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
    }
  };
}

export const updateSelectedCategory = key => ({
  type: types.UPDATE_SELECTED_CATEGORY,
  payload: { key }
});

function updatebobScorecardMetricsSuccess(
  responseData,
  bobScorecardId,
  categoryScorecardId,
  score,
  isCategoryCompleted
) {
  return {
    type: types.UPDATE_BOBSCORECARD_METRICS_SUCCESS,
    payload: {
      responseData,
      bobScorecardId,
      categoryScorecardId,
      score,
      isCategoryCompleted
    }
  };
}
function updateSupplierScorecardMetricsSuccess(
  responseData,
  supplierScorecardId,
  categoryScorecardId,
  score,
  isCategoryCompleted
) {
  return {
    type: types.UPDATE_SUPPLIERSCORECARD_METRICS_SUCCESS,
    payload: {
      responseData,
      supplierScorecardId,
      categoryScorecardId,
      score,
      isCategoryCompleted
    }
  };
}

export function updateBobScorecardMetricDetails(
  categoryId,
  bobScorecardId,
  categoryScorecardId,
  metricScorecardDetails
) {
  return async dispatch => {
    try {
      const metrics = buildScorecardDataToBeSubmitted(
        categoryScorecardId,
        metricScorecardDetails
      );
      const response = await performanceApi.updateBobScorecardMetricDetails(
        categoryId,
        metrics
      );

      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        let isCategoryCompleted = true;
        if (metricScorecardDetails.metrics) {
          metricScorecardDetails.metrics.forEach(metric => {
            if (isCategoryCompleted && metric.subMetrics) {
              metric.subMetrics.forEach(submetric => {
                if (submetric.score < 1 && submetric.isApplicable) {
                  isCategoryCompleted = false;
                }
              });
            }
            if (
              isCategoryCompleted &&
              (metric.score === 0 || !metric.score) &&
              metric.isApplicable
            ) {
              isCategoryCompleted = false;
            }
          });
        }
        if (isCategoryCompleted && !metricScorecardDetails.comments) {
          isCategoryCompleted = false;
        }
        dispatch(
          updatebobScorecardMetricsSuccess(
            response.data,
            bobScorecardId,
            categoryScorecardId,
            metrics.score,
            isCategoryCompleted
          )
        );
        dispatch(
          requestProcessedMessageSuccess(Message.NewRecordCreatedSuccessfully)
        );
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      Logger.getInstance().logException(error);
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
    }
  };
}
export function updateSupplierScorecardMetricDetails(
  categoryId,
  supplierScorecardId,
  categoryScorecardId,
  metricScorecardDetails
) {
  return async dispatch => {
    try {
      const metrics = buildScorecardDataToBeSubmitted(
        categoryScorecardId,
        metricScorecardDetails
      );
      const response = await performanceApi.updateSupplierScorecardMetricDetails(
        categoryId,
        metrics
      );

      if (response.validationResult.isValid) {
        response.data = !response.data ? [] : response.data;
        let isCategoryCompleted = true;
        if (metricScorecardDetails.metrics) {
          metricScorecardDetails.metrics.forEach(metric => {
            if (metric.subMetrics) {
              metric.subMetrics.forEach(submetric => {
                if (submetric.score < 1 && submetric.isApplicable) {
                  isCategoryCompleted = false;
                }
              });
            }
            if (
              isCategoryCompleted &&
              (metric.score === 0 || !metric.score) &&
              metric.isApplicable
            ) {
              isCategoryCompleted = false;
            }
          });
        }
        if (isCategoryCompleted && !metricScorecardDetails.comments) {
          isCategoryCompleted = false;
        }

        dispatch(
          updateSupplierScorecardMetricsSuccess(
            response.data,
            supplierScorecardId,
            categoryScorecardId,
            metrics.score,
            isCategoryCompleted
          )
        );
        dispatch(
          requestProcessedMessageSuccess(Message.RecordHasAddedSucccessfully)
        );
      } else {
        dispatch(handleValidationError(response.validationResult.errors));
        Logger.getInstance().logValidationError(response.validationResult);
      }
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}

export function loadSupplierScorecardAvgBOBScoreHistory(categoryId) {
  return async dispatch => {
    try {
       const response = await performanceApi.getSupplierScorecardAvgBOBScoreHistory(
        categoryId
      );
      if (!response.validationResult.isValid) {
        dispatch(handleValidationError(response.validationResult.errors));
      }
      dispatch(
        loadSupplierScorecardAvgBOBScoreHistorySuccess({
          categoryId,
          BOBAvgScoreHistory: response.data
        })
      );
    } catch (error) {
      dispatch(requestUnprocessedMessageSuccess(error.errormessage, error.tcv));
      Logger.getInstance().logException(error);
    }
  };
}
