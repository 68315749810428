import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import './LineGraphControl.css';

export class LineGraphControl extends Component {
  data = {
    labels: [],
    datasets: [
      {
        fill: false,
        lineTension: 0.1,
        backgroundColor: '#CECECE',
        borderColor: '#CECECE',
        borderCapStyle: 'butt',
        borderWidth: 1,
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: '#0077d8',
        pointBackgroundColor: 'f00',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#0077d8',
        pointHoverBorderColor: '#0077d8',
        pointHoverBorderWidth: 2,
        pointRadius: 2,
        pointHitRadius: 10,
        pointStyle: 'circle'
      }
    ],
    legend: {
      display: false,
      labels: {
        fontColor: '#024db1'
      }
    }
  };

  plugins = [ChartDataLabels];

  options = {
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        offset: 0,
        font: {
          weight: 'bold'
        }
      }
    },
    layout: {
      padding: {
        left: 50,
        right: 50,
        top: 0,
        bottom: 10
      }
    },
    legend: {
      display: false,
      labels: {
        fontColor: 'rgb(255, 99, 132)'
      }
    },
    title: {
      display: true,
      position: 'top'
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          display: false,
          gridLines: {
            display: false
          },
          ticks: {
            min: 0,
            max: 10,
            stepSize: 1
          }
        }
      ]
    }
  };

  constructor(props) {
    super(props);
    this.GetAriaText = this.GetAriaText.bind(this);
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
    );
  }

  GetAriaText() {
    const { plotPoints } = this.props;
    let text = '';
    if (plotPoints) {
    plotPoints.forEach(point => {
      text = `${text + point.x}  Score ${point.y}\r\n`;
    });
  }
    return text;
  }

  render() {
    const { plotPoints, header, label } = this.props;

    if (plotPoints) {
    this.data.labels = plotPoints.map(plot => (plot === null ? '' : plot.x));
    this.data.datasets[0].data = plotPoints.map(plot => (plot === null ? '' : Number(plot.y).toFixed(1)));
    this.data.datasets[0].label = label;
    }
    this.options.title.text = header;
    return (
      <div
        aria-label={`${header} History ${this.GetAriaText(plotPoints)}`}
        className="graphDiv"
      >
        <Line
          height={50}
          data={this.data}
          options={this.options}
          plugins={this.plugins}
        />
      </div>
    );
  }
}

export default LineGraphControl;

LineGraphControl.propTypes = {
  plotPoints: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};
