/* eslint-disable no-console */
import { PageTour } from '@pagetour/sdk';


export function pageTourInit(token, callback, pageTourConfig) {
  PageTour.init({
    apiUrl: pageTourConfig.apiUrl,
    applicationName: 'SupplierScorecard',
    tenantId: pageTourConfig.tenantId,
    resourceId: pageTourConfig.pageTourId,
    navigator: {
      navigateToContext: callback,
      getCurrentPageContext: context => {
        const url = new URL(window.location.href);
        const pageUrl = url.pathname + url.search + url.hash;

        // eslint-disable-next-line no-param-reassign
        context.state = pageUrl;
        // eslint-disable-next-line no-param-reassign
        context.url = pageUrl;
        return context;
      },
      callbackBeforeTourStart: () => {
        console.log('Callback after tour start');
      },
      callbackOnTourSaved: tour => {
        this.snackBar.open('Successfully saved tour', tour.id, {
          duration: 5000
        });
        console.log(`Tour saved callback ${tour.id}`);
      },
      callbackOnTourExported: tour => {
        console.log(`Tour exported callback ${tour.id}`);
      },
      callbackOnAuthoringStart: () => {
        console.log('Authoring callback');
      },
      callbackOnTourStepFailure: (tour, stepIndex, stepErrorMessage) => {
        if (stepErrorMessage) {
          this.snackBar.open(stepErrorMessage, tour.id, {
            duration: 5000
          });
        } else {
          this.snackBar.open(
            'Something went wrong with this tutorial. We are sorry.',
            tour.id,
            {
              duration: 5000
            }
          );
        }

        console.log(`Tour failed callback ${tour.id}`);
      }
    },
    tokenProvider: {
      acquireToken(appId, tokencallback) {
        tokencallback(token);
      }
    },

    userInfo: {
      usePermissionsApi: true,
      getCurrentUser: () => 'radsag@microsoft.com',
      getCurrentUserPermissions: () => [
        'get',
        'list',
        'update',
        'create',
        'import',
        'export',
        'delete'
      ]
    }
  });
}
export async function openAuthorTourDialog() {
  const pageTour = PageTour.GetInstance();
  await pageTour.openPageTourAuthorDialog();
}
export async function openManageTourDialog() {
  const pageTour = PageTour.GetInstance();
  await pageTour.openPageTourManageDialog();
}
export function playTour(tour) {
  const pagetour = PageTour.GetInstance();
  pagetour.autoPlayByTourObject(tour, 10);
}

export function openPageTourEditDialog(tour) {
  const pagetour = PageTour.GetInstance();
  pagetour.openPageTourEditDialog(tour);
}
export async function getTours() {
  const pageTour = PageTour.GetInstance();
  const url = new URL(window.location.href);
  const pageUrl = url.pathname + url.search + url.hash;
  console.log(pageUrl);
  return pageTour.getTours(pageUrl);
}
