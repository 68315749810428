import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { FocusTrapZone } from '@fluentui/react/lib/FocusTrapZone';
import { ComboBox } from '@fluentui/react/lib/ComboBox';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { Label } from '@fluentui/react/lib/Label';
import { TextField } from '@fluentui/react/lib/TextField';
import { connect } from 'react-redux';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Customizer, getId } from 'office-ui-fabric-react';
import { LayerHost } from '@fluentui/react/lib/Layer';
import * as suppliersActions from '../../../redux/actions/onboardedSuppliersActions';
import { SupplierOnboardingValidationMessage, Message } from '../../../common/consts/validationMessages';
import './ManageOnboardedSupplier.css';
import validateUser from '../../../api/GraphService';
import { Logger } from '../../../infra/logging/telemetryLogger';
import * as performanceApi from '../../../api/performanceManagerApi';
import { PoliteAnnouncement } from '../../../redux/actions/announcementActions';
import ErrorDiv from '../../hoc/errordiv';
import ContractDetailsList from '../contractDetails/ContractDetailsList';

export class ManageOnboardedSupplier extends Component {
  layerHostId = getId('layerHost');

  constructor(props) {
    super(props);
    this.logger = Logger.getInstance();
    this.supplierList = props.suppliers.map((eachSupplier) => ({
      value: eachSupplier.supplierId,
      label: eachSupplier.supplierName,
      annualSpend: eachSupplier.annualSpend,
    }));

    this.cadenceComboOptions = props.cadences.map((eachCadence) => ({
      key: eachCadence.cadenceId,
      text: eachCadence.cadenceTypeName,
    }));

    this.supplierTypeComboOptions = props.supplierTypes.map(
      (eachSupplierType) => ({
        key: eachSupplierType.supplierTypeId,
        text: eachSupplierType.supplierType,
      })
    );

    this.procurementTypeComboOptions = props.procurementTypes.map(
      (procurementType) => ({
        key: procurementType.procurementTypeId,
        text: procurementType.procurementTypeName
      })
    );
    this.domainComboOptions = props.domains.map((eachDomain) => ({
      key: eachDomain.supplierDomainId,
      text: eachDomain.supplierDomainName,
      selected: false,
    }));

    const getContractIds = () => {
      const contractIds = [];
      if (props?.onboardSupplier?.contracts?.length) {
          props.onboardSupplier.contracts.forEach(contract => {
          contractIds.push(contract.masterAgreementContractID);
        });
      }
      return contractIds;
    };


    this.state = {
      isValueEdited: false,
      isClearEnable: false,
      filteredDomainComboOptions: [],
      selectedDomain: [],
      selectedSupplierId: 0,
      selectedSupplierName: null,
      selectedAnnualSpend: null,
      selectedLead1: null,
      lead1Alias: null,
      selectedLead2: null,
      lead2Alias: null,
      selectedCadence: null,
      seletcedSupplierType: null,
      selectedProcurementType: null,
      errorMsgForLead1: '',
      errorMsgForLead2: '',
      hasLead1error: false,
      hasLead2error: false,
      lead1Loading: false,
      lead2Loading: false,
      selectedLead1DisplayName: null,
      selectedLead2DisplayName: null,
      options: [],
      supplierOptions: [],
      isSelectedOption: false,
      selectedLead1Options: [],
      selectedLead2Options: [],
      inValidSupplier: false,
      supplerLoading: false,
      isSelectedSupplier: false,
      contractIds: getContractIds(),
      clearContractTable: false,
      showContractError: false,
      errorMessage: 'error saving data...'
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...prevState,
      selectedSupplierId:
        prevState.selectedSupplierId !== 0
          ? prevState.selectedSupplierId
          : nextProps.onboardSupplier.supplierId,
      selectedSupplierName:
        prevState.selectedSupplierName !== null
          ? prevState.selectedSupplierName
          : nextProps.onboardSupplier.supplierName,
      selectedAnnualSpend:
        prevState.selectedAnnualSpend !== null
          ? prevState.selectedAnnualSpend
          : nextProps.onboardSupplier.uvpAnnualSpend,
      selectedLead1:
        prevState.selectedLead1 !== null
          ? prevState.selectedLead1
          : nextProps.onboardSupplier.lead1Alias,
      selectedLead2:
        prevState.selectedLead2 !== null
          ? prevState.selectedLead2
          : nextProps.onboardSupplier.lead2Alias,
      selectedCadence:
        prevState.selectedCadence !== null
          ? prevState.selectedCadence
          : nextProps.onboardSupplier.cadence,
      seletcedSupplierType:
        prevState.seletcedSupplierType !== null
          ? prevState.seletcedSupplierType
          : nextProps.onboardSupplier.supplierType,
      selectedProcurementType:
        prevState.selectedProcurementType !== null
          ? prevState.selectedProcurementType
          : nextProps.onboardSupplier.procurementType,
      lead1Alias:
        prevState.lead1Alias !== null
          ? prevState.lead1Alias
          : nextProps.supplierlead1.alias,
      lead2Alias:
        prevState.lead2Alias !== null
          ? prevState.lead2Alias
          : nextProps.supplierlead2.alias,
      selectedDomain:
        prevState.selectedDomain.length === 0 &&
          nextProps.onboardSupplier.domains !== undefined &&
          nextProps.onboardSupplier.domains.length !== 0
          ? nextProps.onboardSupplier.domains
          : prevState.selectedDomain,
      selectedLead1DisplayName:
        prevState.selectedLead1DisplayName !== null
          ? prevState.selectedLead1DisplayName
          : nextProps.onboardSupplier.lead1DisplayName,
      selectedLead2DisplayName:
        prevState.selectedLead2DisplayName !== null
          ? prevState.selectedLead2DisplayName
          : nextProps.onboardSupplier.lead2DisplayName,
    };
  }

  componentDidMount() {
    const {
    cadences, scorecardSupplierId, domains
    } = this.props;
    const { selectedProcurementType } = this.state;
    this.setState({
      isSelectedOption: scorecardSupplierId !== 0,
    });
    if (scorecardSupplierId === 0) {
      const defaultcadence = cadences.filter((item) => item.isDefault === true);
      this.setState({
        selectedCadence:
          defaultcadence.length !== 0 ? defaultcadence[0].cadenceTypeName : '',
        isSelectedOption: scorecardSupplierId !== 0,
      });
    }
    if (document.getElementById('defaultCadence')) {
      document
        .getElementById('defaultCadence')
        .getElementsByTagName('input')[0]
        .setAttribute('placeholder', ' Select Default Cadence');
    }
    if (document.getElementById('SupplierType')) {
      document
        .getElementById('SupplierType')
        .getElementsByTagName('input')[0]
        .setAttribute('placeholder', ' Select Supplier Type');
    }

    if (selectedProcurementType) {
    this.setState({
      filteredDomainComboOptions: domains
        .filter((domain) => domain.procurementTypeId === selectedProcurementType?.key)
        .map((eachDomain) => ({
          key: eachDomain.supplierDomainId,
          text: eachDomain.supplierDomainName
        }))
    });
  }
  }

  onChangeDomain = (event, item) => {
    if (item !== undefined) {
      const { selectedDomain } = this.state;
      const updatedSelectedDomain = [...selectedDomain];
      const currIndex = updatedSelectedDomain.indexOf(item.text);
      if (currIndex >= -1) {
        if (currIndex !== 0 && item.selected) {
          updatedSelectedDomain.push(item.text);
        } else {
          updatedSelectedDomain.splice(currIndex, 1);
        }
      }
      this.setState({
        isClearEnable: true,
        isValueEdited: true,
        selectedDomain: updatedSelectedDomain,
      });
    }
  };

  onChangeCadence = (event, item) => {
    if (item !== undefined) {
      this.setState({
        selectedCadence: item,
        isClearEnable: true,
        isValueEdited: true,
      });
    }
  };

  onChangeSupplierType = (event, item) => {
    if (item !== undefined) {
      if (item.value === 3) {
        // default procurement type is Indirect and cadence is Monthly for Monitor supplier
        const { cadences, domains } = this.props;
        const procurementType = this.procurementTypeComboOptions[0];
        const monthlyCadence = cadences.filter((cadence) => cadence.cadenceTypeName === 'Monthly');
        this.setState({
          selectedProcurementType: procurementType,
          selectedCadence: {
            label: monthlyCadence[0].cadenceTypeName,
            value: monthlyCadence[0].cadenceId
          },
          filteredDomainComboOptions: domains
          .filter((domain) => domain.procurementTypeId === procurementType?.value)
          .map((eachDomain) => ({
            key: eachDomain.supplierDomainId,
            text: eachDomain.supplierDomainName
          }))
        });
      }
      this.setState({
        seletcedSupplierType: item,
        isClearEnable: true,
        isValueEdited: true,
      });
    }
  };

  onChangeProcurementType = (event, item) => {
    if (item !== undefined) {
      const { domains } = this.props;
      this.setState({
        selectedProcurementType: item,
        isClearEnable: true,
        isValueEdited: true,
        filteredDomainComboOptions: domains
          .filter((domain) => domain.procurementTypeId === item.key)
          .map((eachDomain) => ({
            key: eachDomain.supplierDomainId,
            text: eachDomain.supplierDomainName
          }))
      });
    }
  };

  onClearData = () => {
    let defaultcadence = [];
    const { cadences, scorecardSupplierId, onboardSupplier } = this.props;
    if (scorecardSupplierId === 0) {
      defaultcadence = cadences.filter((item) => item.isDefault === true);
      this.lead1Typeahead.getInstance().clear();
      this.lead2Typeahead.getInstance().clear();
      this.supplierTypeahead.getInstance().clear();
    }

    this.setState({
      selectedLead1Options: scorecardSupplierId
        ? [onboardSupplier.lead1Alias]
        : [],
      selectedLead2Options: scorecardSupplierId
        ? [onboardSupplier.lead2Alias]
        : [],
      isValueEdited: false,
      selectedSupplierName: null,
      selectedAnnualSpend: null,
      selectedLead1: null,
      selectedLead2: null,
      selectedCadence:
        scorecardSupplierId === 0 && defaultcadence.length !== 0
          ? defaultcadence[0].cadenceTypeName
          : null,
      seletcedSupplierType: null,
      selectedProcurementType: null,
      selectedDomain: [],
      errorMsgForLead1: '',
      errorMsgForLead2: '',
      isClearEnable: false,
      hasLead1error: false,
      hasLead2error: false,
      lead1Loading: false,
      lead2Loading: false,
      isLoading: false,
      selectedLead1DisplayName: null,
      selectedLead2DisplayName: null,
      contractIds: [],
      showContractError: false
    });
    this.toggleClearContractTable(true);
  };

  async onAddSupplier(event) {
    event.preventDefault();
    const {
      addSupplier,
      onboardSupplier,
      scorecardSupplierId,
      updateSupplier,
      unmanagedPerformanceSpend,
      loggedInUser,
      politeAnnouncement,
    } = this.props;

    const {
      selectedSupplierId,
      selectedSupplierName,
      selectedAnnualSpend,
      selectedCadence,
      seletcedSupplierType,
      selectedProcurementType,
      selectedDomain,
      lead1Alias,
      lead2Alias,
      selectedLead1DisplayName,
      selectedLead2DisplayName,
      contractIds
    } = this.state;
    const userAlias = loggedInUser.alias.split('@')[0];
    const uvpAnnualSpend =
      selectedAnnualSpend !== undefined
        ? selectedAnnualSpend.split('$')[1]
        : selectedAnnualSpend;
    onboardSupplier.supplierId = selectedSupplierId;
    onboardSupplier.supplierName = selectedSupplierName;
    onboardSupplier.uvpAnnualSpend = uvpAnnualSpend;
    onboardSupplier.lead1Alias = lead1Alias;
    onboardSupplier.lead2Alias = lead2Alias;
    onboardSupplier.lead1DisplayName = selectedLead1DisplayName;
    onboardSupplier.lead2DisplayName = selectedLead2DisplayName;
    onboardSupplier.cadence = selectedCadence?.text;
    onboardSupplier.supplierType = seletcedSupplierType?.text;
    onboardSupplier.procurementTypeId = selectedProcurementType?.key;
    onboardSupplier.procurementType = selectedProcurementType?.text;
    onboardSupplier.domains = selectedDomain;
    onboardSupplier.createdBy = userAlias;
    onboardSupplier.modifiedBy = userAlias;
    onboardSupplier.contractIds = contractIds;
    delete onboardSupplier.contracts;
    if (
      onboardSupplier.domains.length !== 0 &&
      onboardSupplier.supplierName !== undefined &&
      onboardSupplier.lead1Alias !== undefined &&
      onboardSupplier.lead2Alias !== undefined &&
      onboardSupplier.cadence !== undefined &&
      onboardSupplier.supplierType !== undefined
    ) {
      this.setState({
        isLoading: true,
      });

      try {
        if (scorecardSupplierId === 0) {
          try {
            politeAnnouncement('Saving data');
            await addSupplier(onboardSupplier);
          } catch (error) {
            this.logger.logException(error);
          }
        } else {
          try {
            politeAnnouncement('Saving data');
            await updateSupplier(
              onboardSupplier,
              scorecardSupplierId,
              unmanagedPerformanceSpend
            );
          } catch (error) {
            this.logger.logException(error);
          }
        }
        this.setState({
          isLoading: false,
        });
      } catch (err) {
        this.setState({
          isLoading: false,
        });
        throw err;
      }
    }
  }

  toggleClearContractTable = (val) => {
    this.setState({ clearContractTable: val });
  };

  addNewContractId = (contractId) => {
    const { contractIds } = this.state;
    this.setState({
      contractIds: [...contractIds, contractId],
      isClearEnable: true,
      clearContractTable: false,
      showContractError: false,
      isValueEdited: true,
    });
  };

  removeContractId = (contractIds) => {
    const { contractIds: cIds } = this.state;
    const newcIds = cIds.filter((cId) => !contractIds.includes(cId));
    this.setState({ contractIds: newcIds, showContractError: false, isValueEdited: true, });
  }

  setErrorMessage = (message) => {
    this.setState({ errorMessage: message, showContractError: true });
  }

  getSupplierTypeHelpText = (seletcedSupplierType) => {
    if (seletcedSupplierType !== undefined) {
      switch (seletcedSupplierType.value) {
        case 3:
          return Message.MonitorSupplierTypeHelpText;
        case 4:
          return Message.NonSrmLiteSupplierTypeHelpText;
        default:
          return '';
      }
    } else {
      return '';
    }
  }

  onSearchLead1 = (alias) => {
    this.setState({
      lead1Loading: true,
      hasLead1error: false,
      errorMsgForLead1: '',
      isSelectedOption: false,
      selectedLead1Options: [],
    });
    const { selectedLead1 } = this.state;
    if (alias !== selectedLead1) {
      if (alias === '') {
        this.setState({
          selectedLead1: undefined,
          errorMsgForLead1: SupplierOnboardingValidationMessage.Lead1NotEntered,
          hasLead1error: true,
          isClearEnable: true,
          lead1Loading: false,
          selectedLead1DisplayName: null,
          isValueEdited: true,
        });
      }
    }
    validateUser(alias).then((json) =>
      this.setState({
        lead1Loading: false,
        options:
          json &&
          json.map((data) => ({
            id: data.userPrincipalName,
            label: `${data.displayName} (${
              data.userPrincipalName.split('@')[0]
              })`,
            displayName: data.displayName,
            alias: data.userPrincipalName.split('@')[0]
          }))
      }));
  };

  onChangeLead1 = (selectedAlias) => {
    if (!selectedAlias.length) {
      this.lead1Typeahead.getInstance().clear();
    }
    const leadAlias = selectedAlias.length ? selectedAlias[0] : null;

    this.setState({
      selectedLead1Options:
        leadAlias && leadAlias.label ? [leadAlias.label] : [],
      selectedLead1: leadAlias && leadAlias.label ? leadAlias.label : null,
      lead1Alias: leadAlias && leadAlias.alias ? leadAlias.alias : null,
      isClearEnable: true,
      selectedLead1DisplayName:
        leadAlias && leadAlias.displayName ? leadAlias.displayName : null,
      isValueEdited: true,
      isSelectedOption: true,
      errorMsgForLead1: '',
      hasLead1error: false,
    });
  };

  onBlurLead1 = () => {
    const { isSelectedOption, selectedLead1 } = this.state;
    if (!isSelectedOption) {
      this.lead1Typeahead.getInstance().clear();
    }

    this.setState({
      selectedLead1: isSelectedOption ? selectedLead1 : null,
      hasLead1error: !isSelectedOption,
      errorMsgForLead1: isSelectedOption
        ? ''
        : SupplierOnboardingValidationMessage.InvalidUser,
      isValueEdited: true,
    });
  };

  onSearchLead2 = (alias) => {
    this.setState({
      lead2Loading: true,
      hasLead2error: false,
      errorMsgForLead2: '',
      isSelectedOption: false,
      selectedLead2Options: [],
    });
    const { selectedLead2 } = this.state;
    if (alias !== selectedLead2) {
      if (alias === '') {
        this.setState({
          selectedLead2: undefined,
          errorMsgForLead2: SupplierOnboardingValidationMessage.Lead2NotEntered,
          hasLead2error: true,
          isClearEnable: true,
          lead2Loading: false,
          selectedLead2DisplayName: null,
          isValueEdited: true,
        });
      }
    }
    validateUser(alias).then((json) =>
      this.setState({
        lead2Loading: false,
        options:
          json &&
          json.map((data) => ({
            id: data.userPrincipalName,
            label: `${data.displayName} (${
              data.userPrincipalName.split('@')[0]
              })`,
            displayName: data.displayName,
            alias: data.userPrincipalName.split('@')[0]
          }))
      }));
  };

  onChangeLead2 = (selectedAlias) => {
    if (!selectedAlias.length) {
      this.lead2Typeahead.getInstance().clear();
    }
    const leadAlias = selectedAlias.length ? selectedAlias[0] : null;

    this.setState({
      selectedLead2Options:
        leadAlias && leadAlias.label ? [leadAlias.label] : [],
      selectedLead2: leadAlias !== '' ? leadAlias : undefined,
      lead2Alias: leadAlias && leadAlias.alias ? leadAlias.alias : null,
      isClearEnable: true,
      selectedLead2DisplayName:
        leadAlias && leadAlias.displayName ? leadAlias.displayName : null,
      isValueEdited: true,
      isSelectedOption: selectedAlias.length > 0,
      errorMsgForLead2: '',
      hasLead2error: false,
    });
  };

  onBlurLead2 = () => {
    const { isSelectedOption, selectedLead2 } = this.state;
    if (!isSelectedOption) {
      this.lead2Typeahead.getInstance().clear();
    }

    this.setState({
      selectedLead2: isSelectedOption ? selectedLead2 : null,
      hasLead2error: !isSelectedOption,
      errorMsgForLead2: isSelectedOption
        ? ''
        : SupplierOnboardingValidationMessage.InvalidUser,
    });
  };

  onSearchSupplier = async (searchText) => {
    this.setState({
      supplerLoading: true,
      isSelectedSupplier: false,
    });
    const supplierData = await performanceApi.getSuppliers(searchText);
    this.setState({
      supplierOptions:
        supplierData && supplierData.data.length ? supplierData.data : [],
      supplerLoading: false,
    });
  };

  onChangeSupplier = (selectedSupplier) => {
    if (!selectedSupplier.length) {
      this.supplierTypeahead.getInstance().clear();
    }
    const supplier = selectedSupplier.length ? selectedSupplier[0] : null;
    this.setState({
      selectedSupplierId:
        supplier && supplier.supplierId ? supplier.supplierId : 0,
      selectedSupplierName:
        supplier && supplier.supplierName ? supplier.supplierName : null,
      selectedAnnualSpend: supplier ? `$${supplier.annualSpend}` : null,
      isClearEnable: true,
      isValueEdited: true,
      isSelectedSupplier: selectedSupplier.length > 0,
    });
  };

  onBlurSupplier = () => {
    const { isSelectedSupplier } = this.state;
    if (!isSelectedSupplier) {
      this.supplierTypeahead.getInstance().clear();
    }
  };

  calculateKeysForDomainName = (selectedDomains = []) => {
    const { domains } = this.props;
    const selectedKeys = [];
    selectedDomains.forEach((selectedDomainName) => {
      for (let i = 0; i < domains.length; i += 1) {
        if (domains[i]?.supplierDomainName === selectedDomainName) {
          selectedKeys.push(domains[i]?.supplierDomainId);
          break;
        }
      }
    });
    return selectedKeys;
  }

  render() {
    const {
      onCloseModal,
      scorecardSupplierId,
      isSuccessSavingData,
      isErrorSavingData,
      addContract,
      onboardSupplier
    } = this.props;
    const {
      isValueEdited,
      selectedDomain,
      selectedSupplierName,
      selectedAnnualSpend,
      selectedLead1,
      selectedLead2,
      selectedCadence,
      seletcedSupplierType,
      selectedProcurementType,
      errorMsgForLead1,
      errorMsgForLead2,
      isClearEnable,
      hasLead1error,
      hasLead2error,
      lead1Loading,
      lead2Loading,
      isLoading,
      options,
      supplierOptions,
      selectedLead1Options,
      selectedLead2Options,
      inValidSupplier,
      supplerLoading,
      filteredDomainComboOptions,
      contractIds,
      clearContractTable,
      errorMessage,
      showContractError
    } = this.state;

    const comboBoxStyles = {
      container: {
          label: { fontSize: '14px !important', margin: '0 10px' }
        },
      optionsContainerWrapper: { maxHeight: '350px' },
      root: {
        '::after': {
        borderRadius: '4px'
        }
      }
    };
    return (
      <div>
        {isLoading ? <Spinner className="spinner" /> : null}
        {isSuccessSavingData ? onCloseModal() : null}
        <div className="row pagemargin">

          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show
            size="xl"
            onHide={onCloseModal}
            animation={false}
            dialogClassName="container"
          >
            <FocusTrapZone>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <div className="header ml15 mt25">
                    <div className="row">
                      <div className="col-md-12">
                        <h1 id={this.titleId} className="modal-title">
                          {scorecardSupplierId === 0
                            ? 'Add Supplier'
                            : 'Edit Supplier'}
                        </h1>
                      </div>
                      <div className="col-md-12 requiredtext">
                        <span>All fields are required</span>
                      </div>
                    </div>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={this.onAddSupplier.bind(this)}>
                  <div className="row mt25 ml15 mr15">
                    <div className="col-md-6 mt15">
                      <div className="col-md-12">
                        <Label htmlFor="supplierName">SUPPLIER NAME</Label>
                        <AsyncTypeahead
                          isLoading={supplerLoading}
                          placeholder="Select Supplier Name"
                          emptyLabel="No Records found"
                          delay={300}
                          minLength={2}
                          onSearch={this.onSearchSupplier}
                          options={supplierOptions}
                          onBlur={this.onBlurSupplier}
                          onChange={this.onChangeSupplier}
                          defaultInputValue={selectedSupplierName}
                          isInvalid={inValidSupplier}
                          maxHeight="250px"
                          labelKey="supplierName"
                          searchText="Searching..."
                          disabled={scorecardSupplierId !== 0}
                          // eslint-disable-next-line no-return-assign
                          ref={(supplierTypeahead) =>
                            (this.supplierTypeahead = supplierTypeahead)
                          }
                          inputProps={{
                            className: 'typeahead-css',
                            autoComplete: 'name',
                            id: 'supplierName'
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 mt15">
                      <div className="col-md-12">
                        <ComboBox
                          selectedKey={
                            selectedProcurementType !== undefined
                              ? selectedProcurementType?.key
                              : null
                          }
                          useAriaLabelAsText
                          allowFreeInput
                          useComboBoxAsMenuWidth
                          autoComplete="on"
                          iconButtonProps={{ role: 'button', ariaLabel: 'expand dropdown button' }}
                          comboBoxOptionStyles={{ maxHeight: '280px' }}
                          calloutProps={{ doNotLayer: true }}
                          placeholder="Select Procurement Type"
                          options={this.procurementTypeComboOptions}
                          onChange={this.onChangeProcurementType}
                          styles={comboBoxStyles}
                          label="PROCUREMENT TYPE"
                          title="ProcurementType"
                          aria-label="ProcurementType"
                          disabled={scorecardSupplierId !== 0
                            || (seletcedSupplierType && seletcedSupplierType.value === 3)}
                          id="ProcurementType"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row ml15 mr15">
                    <div className="col-md-6 mt15">
                      <div className="col-md-12">
                        <Label htmlFor="trailPoAmount">
                          TRAILING 12-MONTH PO AMOUNT (USD)
                        </Label>
                        <TextField
                          placeholder="Trailing 12-month PO amount"
                          defaultValue={
                            selectedAnnualSpend
                              ? '$'.concat(
                                parseInt(selectedAnnualSpend.split('$')[1], 10)
                                  .toLocaleString(navigator.language, {
                                    minimumFractionDigits: 2,
                                  })
                                  .toString()
                              )
                            : selectedAnnualSpend
                        }
                          disabled
                          readOnly
                          id="trailPoAmount"
                        />
                      </div>
                    </div>
                  <div className="col-md-6 mt15">
                    <div className="col-md-12">
                      <Customizer
                        scopedSettings={{ Layer: { hostId: this.layerHostId } }}
                      >
                        <ComboBox
                          autoComplete="on"
                          useComboBoxAsMenuWidth
                          multiSelect
                          ariaLabel={seletcedSupplierType?.key === 1 ? 'Industry' : 'Domain'}
                          label={seletcedSupplierType?.key === 1 ? 'INDUSTRY' : 'DOMAIN'}
                          placeholder={seletcedSupplierType?.key === 1 ? 'Select Industry' : 'Select Domain'}
                          disabled={!selectedProcurementType}
                          options={filteredDomainComboOptions}
                          onChange={this.onChangeDomain}
                          defaultSelectedKey={this.calculateKeysForDomainName(selectedDomain)}
                          id="domain"
                          selectedKey={this.calculateKeysForDomainName(selectedDomain)}
                          title={seletcedSupplierType?.key === 1 ? 'Industry' : 'Domain'}
                        />
                      </Customizer>
                      <LayerHost
                        id={this.layerHostId}
                        style={{
                          position: 'absolute',
                          zIndex: 1
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mt15">
                    <div className="col-md-12">
                      <div>
                        <Label htmlFor="lead1">LEAD 1</Label>
                        <TooltipHost
                          content={hasLead1error ? errorMsgForLead1 : null}
                        >
                          <AsyncTypeahead
                            isLoading={lead1Loading}
                            placeholder="Select Lead 1"
                            emptyLabel="No Records found"
                            delay={300}
                            minLength={2}
                            onSearch={this.onSearchLead1}
                            filterBy={['id']}
                            options={options}
                            onBlur={this.onBlurLead1}
                            onChange={this.onChangeLead1}
                            defaultInputValue={selectedLead1}
                            isInvalid={hasLead1error}
                            maxHeight="170px"
                            searchText="Searching..."
                            selected={selectedLead1Options}
                            // eslint-disable-next-line no-return-assign
                            ref={(lead1Typeahead) =>
                              (this.lead1Typeahead = lead1Typeahead)
                            }
                            inputProps={{
                              className: 'typeahead-css',
                              autoComplete: 'name',
                              id: 'lead1'
                            }}
                          />
                        </TooltipHost>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mt15">
                    <div className="col-md-12">
                      <div>
                        <Label htmlFor="lead2">LEAD 2</Label>
                        <TooltipHost
                          content={hasLead2error ? errorMsgForLead2 : null}
                        >
                          <AsyncTypeahead
                            isLoading={lead2Loading}
                            placeholder="Select Lead 2"
                            emptyLabel="No Records found"
                            delay={300}
                            minLength={2}
                            onSearch={this.onSearchLead2}
                            filterBy={['id']}
                            options={options}
                            onChange={this.onChangeLead2}
                            onBlur={this.onBlurLead2}
                            defaultInputValue={selectedLead2}
                            selected={selectedLead2Options}
                            isInvalid={hasLead2error}
                            maxHeight="170px"
                            searchText="Searching..."
                            // eslint-disable-next-line no-return-assign
                            ref={(lead2Typeahead) =>
                              (this.lead2Typeahead = lead2Typeahead)
                            }
                            inputProps={{
                              className: 'typeahead-css',
                              autoComplete: 'name',
                              id: 'lead2'
                            }}
                          />
                        </TooltipHost>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mt15">
                    <div className="col-md-12">
                      <ComboBox
                        label="DEFAULT CADENCE"
                        selectedKey={selectedCadence?.key}
                        placeholder="Select Default Cadence"
                        options={this.cadenceComboOptions}
                        onChange={this.onChangeCadence}
                        useAriaLabelAsText
                        allowFreeInput
                        useComboBoxAsMenuWidth
                        autoComplete="on"
                        styles={comboBoxStyles}
                        iconButtonProps={{ role: 'button', ariaLabel: 'expand dropdown button' }}
                        disabled={seletcedSupplierType && seletcedSupplierType.value === 3}
                        id="defaultCadence"
                        comboBoxOptionStyles={{ maxHeight: '280px' }}
                        calloutProps={{ doNotLayer: true }}
                        title="defaultCadence"
                        aria-label="defaultCadence"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mt15">
                    <div className="col-md-12">
                      <ComboBox
                        selectedKey={
                          seletcedSupplierType !== undefined
                            ? seletcedSupplierType?.key
                            : null
                        }
                        useAriaLabelAsText
                        allowFreeInput
                        useComboBoxAsMenuWidth
                        autoComplete="on"
                        iconButtonProps={{ role: 'button', ariaLabel: 'expand dropdown button' }}
                        label="SUPPLIER TYPE"
                        placeholder="Select Supplier Type"
                        options={this.supplierTypeComboOptions}
                        onChange={this.onChangeSupplierType}
                        styles={comboBoxStyles}
                        blurInputOnSelect
                        id="SupplierType"
                        title="SupplierType"
                        comboBoxOptionStyles={{ maxHeight: '280px' }}
                        calloutProps={{ doNotLayer: true }}
                        aria-label="SupplierType"
                      />
                      <Label id="SupplierTypeHelpText" aria-live="assertive">
                        {
                          this.getSupplierTypeHelpText(seletcedSupplierType)
                        }
                      </Label>
                    </div>
                  </div>
                  </div>
                <div className="col-md-12">
                  <ContractDetailsList
                    id="contractId"
                    addContractHttp={addContract}
                    addNewContractId={this.addNewContractId}
                    removeContractId={this.removeContractId}
                    contractIds={contractIds}
                    clearTable={clearContractTable}
                    toggleClearContractTable={this.toggleClearContractTable}
                    setErrorMessage={this.setErrorMessage}
                    contracts={onboardSupplier?.contracts}
                  />
                </div>
                <div className="row modalfooter mt40 mr15 mb35 ml15">
                  <div className="col-md-8" />
                  <div className="col-md-2">
                    <PrimaryButton
                      disabled={!isClearEnable}
                      onClick={this.onClearData.bind(this)}
                      className={
                        !isClearEnable ? 'btn-clear disabled' : 'btn-clear'
                      }
                      text="Clear"
                      value="Clear"
                      type="Clear"
                    />
                  </div>
                  <div className="col-md-2">
                    <PrimaryButton
                      type="Submit"
                      text="Save"
                      value="Save"
                      disabled={
                        !(
                          selectedDomain.length &&
                          selectedSupplierName !== undefined &&
                          selectedLead1 !== undefined &&
                          selectedLead2 !== undefined &&
                          selectedCadence !== undefined &&
                          seletcedSupplierType !== undefined &&
                          selectedProcurementType !== undefined &&
                          isValueEdited
                        )
                      }
                      className={
                        !(
                          selectedDomain.length &&
                          selectedSupplierName !== undefined &&
                          selectedLead1 !== undefined &&
                          selectedLead2 !== undefined &&
                          selectedCadence !== undefined &&
                          seletcedSupplierType !== undefined &&
                          selectedProcurementType !== undefined &&
                          isValueEdited
                        )
                          ? 'btn-save disabled'
                          : 'btn-save'
                      }
                    />
                  </div>
                </div>
                </form>
                {isErrorSavingData || showContractError ?
                <ErrorDiv
                  error={errorMessage}
                  hideRetry
                />
                : null}
              </Modal.Body>
            </FocusTrapZone>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isSuccessSavingData: state.message.isSuccessSavingData,
  isErrorSavingData: state.message.isErrorSavingData
});

const mapDispatchToProps = {
  addSupplier: suppliersActions.addOnboardSupplier,
  updateSupplier: suppliersActions.updateOnboardedSupplier,
  addContract: suppliersActions.addContract,
  politeAnnouncement: PoliteAnnouncement,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageOnboardedSupplier);

ManageOnboardedSupplier.defaultProps = {
  politeAnnouncement: null,
  isSuccessSavingData: false,
  isErrorSavingData: false,
};

ManageOnboardedSupplier.propTypes = {
  suppliers: PropTypes.array.isRequired,
  domains: PropTypes.array.isRequired,
  cadences: PropTypes.array.isRequired,
  supplierTypes: PropTypes.array.isRequired,
  procurementTypes: PropTypes.array.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onboardSupplier: PropTypes.object.isRequired,
  addSupplier: PropTypes.func.isRequired,
  addContract: PropTypes.func.isRequired,
  updateSupplier: PropTypes.func.isRequired,
  scorecardSupplierId: PropTypes.number.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  supplierlead1: PropTypes.object.isRequired,
  supplierlead2: PropTypes.object.isRequired,
  unmanagedPerformanceSpend: PropTypes.object.isRequired,
  politeAnnouncement: PropTypes.func,
  isSuccessSavingData: PropTypes.bool,
  isErrorSavingData: PropTypes.bool,
};
