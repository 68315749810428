/* eslint-disable import/prefer-default-export */
import * as types from './actionTypes';
import config from '../../config/appConfig';
import { Logger } from '../../infra/logging/telemetryLogger';

const resourcesConfig = config.resources;

function loadResourceSuccess(resource) {
  return { type: types.LOAD_RESOURCE_SUCCESS, resource };
}

export function getResource() {
  return async dispatch => {
    try {
      // const resource = await performanceApi.getResource();
      const resource = [
        {
          id: 1,
          title: 'Support Process',
          isExternal: true,
          link: resourcesConfig.supportProcess
        },
        {
          id: 2,
          title: 'BOB Owner User Guide',
          isExternal: true,
          link: resourcesConfig.bobOwnerUserGuide
        },
        {
          id: 3,
          title: 'Supplier Lead User Guide',
          isExternal: true,
          link: resourcesConfig.supplierLeadUserGuide
        },
        {
          id: 4,
          title: 'Supplier Scorecard Portal',
          isExternal: true,
          link: resourcesConfig.SupplierScorecardPortal
        }
      ];
      dispatch(loadResourceSuccess(resource));
    } catch (error) {
      Logger.getInstance().logException(error);
    }
  };
}
